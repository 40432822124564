import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: 'brick.data.settings.customize_name && (!brick.data.settings.name || !brick.data.settings.name.length)',
            message: 'Campaign name is required',
            inputIdentifiers: ['meta_campaign-name'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.adAccountId || !brick?.data?.settings?.adAccountId.length',
            message: 'Ad account id is required',
            inputIdentifiers: ['meta_campaign-ad_account_id'],
            severity: 'error',
            tabKey: 'settings'
        },
        {
            condition: "!brick.data.settings.objective || brick.data.settings.objective==='' ",
            message: 'Campaign should have objective',
            inputIdentifiers: ['meta_campaign-objective'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: "brick.data.settings.objective!=='OUTCOME_APP_PROMOTION' && brick.data.settings.smart_promotion_type==='SMART_APP_PROMOTION' ",
            message: 'SMART APP PROMOTION could be only used in campaigns with objective type APP PROMOTION',
            inputIdentifiers: ['meta_campaign-objective'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "brick.data.settings.buying_type === 'RESERVED' && !['OUTCOME_AWARENESS','OUTCOME_TRAFFIC','OUTCOME_ENGAGEMENT'].includes(brick.data.settings.objective)",
            message: 'Campaign with reach and frequency buying type should be either awareness, traffic or engagement',
            inputIdentifiers: ['meta_campaign-objective'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data.settings.spend_cap && brick.data.settings.spend_cap!=="0" && brick.data.settings.spend_cap<100',
            message: 'Spend limit should be more than 100',
            inputIdentifiers: ['meta_campaign-spend_cap'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data.settings.budget_optimization_on==="true" && brick.data.settings.objective==="OUTCOME_AWARENESS" && brick.data.settings.bid_strategy && !["LOWEST_COST_WITHOUT_CAP","LOWEST_COST_WITH_BID_CAP"].includes(brick.data.settings.bid_strategy)',
            message: 'For Outcome awareness campaign the bid strategy should be highest volume or bid cap',
            inputIdentifiers: ['meta_campaign-bid_strategy'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data.settings.budget_optimization_on==="true" && brick.data.settings.objective==="OUTCOME_TRAFFIC" && brick.data.settings.bid_strategy && !["LOWEST_COST_WITHOUT_CAP","LOWEST_COST_WITH_BID_CAP","COST_CAP"].includes(brick.data.settings.bid_strategy)',
            message: 'For Outcome traffic campaign the bid strategy can not have ROAS goal as bid strategy',
            inputIdentifiers: ['meta_campaign-bid_strategy'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data.settings.budget_optimization_on==="true" && brick.data.settings.objective==="OUTCOME_ENGAGEMENT" && brick.data.settings.bid_strategy && !["LOWEST_COST_WITHOUT_CAP","LOWEST_COST_WITH_BID_CAP","COST_CAP"].includes(brick.data.settings.bid_strategy)',
            message: 'For Outcome engagement campaign the bid strategy can not have ROAS goal as bid strategy',
            inputIdentifiers: ['meta_campaign-bid_strategy'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data.settings.budget_optimization_on==="true" && brick.data.settings.objective==="OUTCOME_LEADS" && brick.data.settings.bid_strategy && !["LOWEST_COST_WITHOUT_CAP","LOWEST_COST_WITH_BID_CAP","COST_CAP"].includes(brick.data.settings.bid_strategy)',
            message: 'For Outcome leads campaign the bid strategy can not have ROAS goal as bid strategy',
            inputIdentifiers: ['meta_campaign-bid_strategy'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data.settings.budget_optimization_on==="true" && (!brick.data.settings.bid_strategy || brick.data.settings.bid_strategy==="")',
            message: 'When using campaign budget optimization please select bid strategy',
            inputIdentifiers: ['meta_campaign-bid_strategy'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data.settings.budget_optimization_on==="true" && !brick.data.settings.budget_mode',
            message: 'When using campaign budget optimization please select budget mode ',
            inputIdentifiers: ['meta_campaign-budget_mode'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: 'brick.data.settings.budget_optimization_on==="true" && (!brick.data.settings.budget || brick.data.settings.budget==="0")',
            message: 'When using campaign budget optimization please select a budget',
            inputIdentifiers: ['meta_campaign-budget'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                'brick.data.settings.budget_optimization_on==="true" && brick.data.settings.budget_mode==="LIFETIME" && brick.data.settings.spend_cap && brick.data.settings.spend_cap!=="0"',
            message: 'When you are using budget mode lifetime you must remove spend cap',
            inputIdentifiers: ['meta_campaign-spend_cap'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
