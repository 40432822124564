import React from 'react';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import PlacementsBlock from 'components/bricks/components/shared/components/placements-block';
import BricksMultiInputBlockWrapper from 'components/bricks/components/shared/components/bricks-multi-input-block-wrapper';
import useBrick from 'components/bricks/hooks/useBrick';

const Settings: React.FC = () => {
    const { brick } = useBrick();
    return (
        <TabWrapper>
            <BricksMultiInputBlockWrapper editorBlocks={BrickHelpers.getBrickData('meta_adset', 'settingsInputs')} />

            {!brick?.data?.settings?.targeting?.automatic_placements && <PlacementsBlock />}
        </TabWrapper>
    );
};

export default Settings;
