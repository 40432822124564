const defaultData = {
    data: {
        settings: {
            objective: 'OUTCOME_AWARENESS',
            buying_type: 'AUCTION'
        }
    }
};

export default defaultData;
