import { ValidationObject } from 'components/bricks/types/validator.type';

const validators: ValidationObject = {
    validators: [
        {
            condition: 'brick.data.settings.customize_name && (!brick.data.settings.name || !brick.data.settings.name.length)',
            message: 'Ad set name is required',
            inputIdentifiers: ['meta_adset-name'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data?.settings?.adAccountId || !brick?.data?.settings?.adAccountId.length',
            message: 'Ad account id is required',
            inputIdentifiers: ['meta_adset-ad_account_id'],
            severity: 'error',
            tabKey: 'settings'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_APP_PROMOTION' && parent?.data?.settings.smart_promotion_type!=='SMART_APP_PROMOTION' && brick.data.settings.optimization_goal!='APP_INSTALLS' && brick.data.settings.optimization_goal!='APP_INSTALLS_AND_OFFSITE_CONVERSIONS' && brick.data.settings.optimization_goal!='LINK_CLICKS' && brick.data.settings.optimization_goal!='VALUE'",
            message:
                "Campaign with objective 'App promotion' with smart app promotion can only contain 'App installs', 'Link clicks', 'App installs and offsite conversions', 'Value' optimization goals in sets",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_APP_PROMOTION' && parent?.data?.settings.smart_promotion_type==='SMART_APP_PROMOTION' && brick.data.settings.optimization_goal!='APP_INSTALLS' && brick.data.settings.optimization_goal!='APP_INSTALLS_AND_OFFSITE_CONVERSIONS' && brick.data.settings.optimization_goal!='LINK_CLICKS' && brick.data.settings.optimization_goal!='VALUE'",
            message:
                "Campaign with objective 'App promotion' without smart app promotion can only contain 'App installs', 'App installs and offsite conversions', 'Value' optimization goals in sets",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_APP_PROMOTION' && brick.data.settings.destination_type && brick.data.settings.destination_type!=='UNDEFINED'",
            message: "Campaign with objective 'App promotion' can't have destination type",
            inputIdentifiers: ['meta_adset-destination_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective==='OUTCOME_AWARENESS' && brick.data.settings.optimization_goal!=='REACH' && brick.data.settings.optimization_goal!=='AD_RECALL_LIFT'  && brick.data.settings.optimization_goal!=='IMPRESSIONS' && brick.data.settings.optimization_goal!=='THRUPLAY'",
            message: "Campaign with objective 'Awareness' can only contain 'Reach', 'Ad recall lift', 'Impressions' optimization goals in  sets",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && !brick.data.settings.destination_type",
            message: "Campaign with objective 'Engagement' should have destination type",
            inputIdentifiers: ['meta_adset-destination_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && !['ON_VIDEO','ON_POST','ON_EVENT','MESSENGER','INSTAGRAM_DIRECT','WEBSITE','APP','ON_PAGE'].includes(brick.data.settings.destination_type)",
            message: "Campaign with objective 'Engagement' should have destination type",
            inputIdentifiers: ['meta_adset-destination_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_VIDEO' && brick.data.settings.optimization_goal!=='THRUPLAY' && brick.data.settings.optimization_goal!=='TWO_SECOND_CONTINUOUS_VIDEO_VIEWS'",
            message:
                "Campaign with objective 'Engagement' and destination type On video should have optimization event either thruplay or two second continuous video view",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_VIDEO' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message: "Campaign with objective 'Engagement' and destination type On video shouldn't have conversions event",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_POST' && brick.data.settings.optimization_goal!=='POST_ENGAGEMENT' && brick.data.settings.optimization_goal!=='IMPRESSIONS' && brick.data.settings.optimization_goal!=='REACH'",
            message:
                "Campaign with objective 'Engagement' and destination type On post should have optimization goal either POST ENGAGEMENT, IMPRESSIONS or REACH",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_POST' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message: "Campaign with objective 'Engagement' and destination type ON POST shouldn't have conversions event",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_EVENT' && brick.data.settings.optimization_goal!=='EVENT_RESPONSES' && brick.data.settings.optimization_goal!=='IMPRESSIONS' && brick.data.settings.optimization_goal!=='REACH' && brick.data.settings.optimization_goal!=='POST_ENGAGEMENT'",
            message:
                "Campaign with objective 'Engagement' and destination type ON EVENT should have optimization event either POST ENGAGEMENT, IMPRESSIONS, REACH or EVENT RESPONSES",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_EVENT' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message: "Campaign with objective 'Engagement' and destination type ON EVENT shouldn't have conversions event",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'MESSENGER' && brick.data.settings.optimization_goal!=='CONVERSATIONS' && brick.data.settings.optimization_goal!=='LINK_CLICKS'",
            message: "Campaign with objective 'Engagement' and destination type MESSENGER should have optimization event either CONVERSATIONS or LINK CLICKS",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'MESSENGER' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message: "Campaign with objective 'Engagement' and destination type MESSENGER shouldn't have conversions event",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'INSTAGRAM_DIRECT' && brick.data.settings.optimization_goal!=='CONVERSATIONS' && brick.data.settings.optimization_goal!=='LINK_CLICKS'",
            message:
                "Campaign with objective 'Engagement' and destination type INSTAGRAM DIRECT should have optimization event either CONVERSATIONS or LINK CLICKS",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'INSTAGRAM_DIRECT' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message: "Campaign with objective 'Engagement' and destination type INSTAGRAM DIRECT shouldn't have conversions event",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'WEBSITE' && brick.data.settings.optimization_goal!=='OFFSITE_CONVERSIONS' && brick.data.settings.optimization_goal!=='ONSITE_CONVERSIONS' && brick.data.settings.optimization_goal!=='LANDING_PAGE_VIEWS' && brick.data.settings.optimization_goal!=='LINK_CLICKS' && brick.data.settings.optimization_goal!=='IMPRESSIONS' && brick.data.settings.optimization_goal!=='REACH'",
            message:
                "Campaign with objective 'Engagement' and destination type WEBSITE should have optimization event either OFFSITE CONVERSIONS, ONSITE CONVERSIONS, LANDING PAGE VIEWS, IPRESSIONS or REACH",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        // {
        //     condition:
        //         "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'WEBSITE' && (!(brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) && !(parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
        //     message:
        //         "Campaign with objective 'Engagement' and destination type WEBSITE should have conversions event like AddToWishlist, Contact, CustomizeProduct, Donate, FindLocation,, Schedule, Search, StartTrial, SubmitApplication, Subscribe, ViewContent",
        //     inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
        //     tabKey: 'settings',
        //     severity: 'error'
        // },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'APP' && ['APP_INSTALLS_AND_OFFSITE_CONVERSIONS', 'LINK_CLICKS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message:
                "Campaign with objective 'Engagement' and destination type APP should have optimization event either APP_INSTALLS_AND_OFFSITE_CONVERSIONS, LINK_CLICKS, REACH",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'APP' && (!(brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) && !(parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message:
                "Campaign with objective 'Engagement' and destination type WEBSITE should have conversions event like Achieve Level, Activate App, Add to Wishlist, Complete Tutorial, Contact, Customize Product, Donate, Find Location, In-App Ad Click, In-App Ad Impression, Rate, Schedule, Search, Spent Credits, Start Trial, Submit Application, Subscribe, Unlock Achievement, View Content",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'APP' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type && !['LEVEL_ACHIEVED','ADD_TO_WISHLIST','TUTORIAL_COMPLETION','CONTACT','CUSTOMIZE_PRODUCT','DONATE','FIND_LOCATION','RATE','SCHEDULE','SEARCH','SPENT_CREDITS','START_TRIAL','SUBMIT_APPLICATION','SUBSCRIBE','ACHIEVEMENT_UNLOCKED','CONTENT_VIEW'].includes(brick.data.settings.promoted_object.custom_event_type)) || !(parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type && !['LEVEL_ACHIEVED','ADD_TO_WISHLIST','TUTORIAL_COMPLETION','CONTACT','CUSTOMIZE_PRODUCT','DONATE','FIND_LOCATION','RATE','SCHEDULE','SEARCH','SPENT_CREDITS','START_TRIAL','SUBMIT_APPLICATION','SUBSCRIBE','ACHIEVEMENT_UNLOCKED','CONTENT_VIEW'].includes(parent?.data?.settings.promoted_object.custom_event_type)))",
            message:
                "Campaign with objective 'Engagement' and destination type WEBSITE should have conversions event like Achieve Level, Activate App, Add to Wishlist, Complete Tutorial, Contact, Customize Product, Donate, Find Location, In-App Ad Click, In-App Ad Impression, Rate, Schedule, Search, Spent Credits, Start Trial, Submit Application, Subscribe, Unlock Achievement, View Content",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_PAGE' && brick.data.settings.optimization_goal!=='PAGE_LIKES'",
            message: "Campaign with objective 'Engagement' and destination type ON PAGE should have optimization event PAGE LIKES",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_ENGAGEMENT' && brick.data.settings.destination_type === 'ON_PAGE' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message: "Campaign with objective 'Engagement' and destination type ON PAGE shouldn't have conversions event",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        // {
        //     condition: "parent?.data?.settings.objective=='OUTCOME_LEADS' && !['WEBSITE','MESSENGER','APP'].includes(brick.data.settings.destination_type)",
        //     message: "Campaign with objective 'LEADS' can should have destination type WEBSITE, MESSENGER or APP",
        //     inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
        //     tabKey: 'settings',
        //     severity: 'error'
        // },

        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'MESSENGER' && ['LEAD_GENERATION','QUALITY_LEAD'].includes(brick.data.settings.optimization_goal)",
            message: "Campaign with objective 'LEADS' and destination type MESSENGER should have optimization event LEAD GENERATION or QUALITY LEAD",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'MESSENGER' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message: "Campaign with objective 'LEADS' and destination type MESSENGER shouldn't have conversions event",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'WEBSITE' && ['OFFSITE_CONVERSIONS', 'ONSITE_CONVERSIONS', 'LANDING_PAGE_VIEWS', 'LINK_CLICKS', 'IMPRESSIONS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message:
                "Campaign with objective 'Engagement' and destination type WEBSITE should have optimization event either Conversions, Landing Page Views, Link Clicks, Impressions, Daily Unique Reach",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'WEBSITE' && (!(brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) && !(parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message:
                "Campaign with objective 'Engagement' and destination type WEBSITE should have conversions event like Lead, CompleteRegistration, Contact, FindLocation, Schedule, StartTrial, SubmitApplication, Subscribe",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'WEBSITE' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type && !['LEAD','COMPLETE_REGISTRATION','CONTACT','FIND_LOCATION','SCHEDULE','START_TRIAL','SUBMIT_APPLICATION','SUBSCRIBE'].includes(brick.data.settings.promoted_object.custom_event_type)) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type && !['LEAD','COMPLETE_REGISTRATION','CONTACT','FIND_LOCATION','SCHEDULE','START_TRIAL','SUBMIT_APPLICATION','SUBSCRIBE'].includes(parent?.data?.settings.promoted_object.custom_event_type)))",
            message:
                "Campaign with objective 'Engagement' and destination type WEBSITE should have conversions event like Lead, CompleteRegistration, Contact, FindLocation, Schedule, StartTrial, SubmitApplication, Subscribe",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'APP' && ['APP_INSTALLS_AND_OFFSITE_CONVERSIONS', 'LINK_CLICKS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message:
                "Campaign with objective 'Leads' and destination type APP should have optimization event either App App installs and offsite conversions, link clicks and reach",
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'APP' && (!(brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type) && !(parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type))",
            message:
                "Campaign with objective 'Engagement' and destination type APP should have conversions event like Complete Registration, Complete Tutorial, Contact, Find Location, Schedule, Start Trial, Submit Application, Subscribe",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_LEADS' && brick.data.settings.destination_type === 'APP' && ((brick.data.settings.promoted_object && brick.data.settings.promoted_object.custom_event_type && !['COMPLETE_REGISTRATION','TUTORIAL_COMPLETION','CONTACT','FIND_LOCATION','SCHEDULE','START_TRIAL','SUBMIT_APPLICATION','SUBSCRIBE'].includes(brick.data.settings.promoted_object.custom_event_type)) || (parent?.data?.settings.promoted_object && parent?.data?.settings.promoted_object.custom_event_type && !['LEAD','COMPLETE_REGISTRATION','CONTACT','FIND_LOCATION','SCHEDULE','START_TRIAL','SUBMIT_APPLICATION','SUBSCRIBE'].includes(parent?.data?.settings.promoted_object.custom_event_type)))",
            message:
                "Campaign with objective 'Engagement' and destination type WEBSITE should have conversions event like Complete Registration, Complete Tutorial, Contact, Find Location, Schedule, Start Trial, Submit Application, Subscribe",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        // {
        //     condition: "parent?.data?.settings.objective=='OUTCOME_TRAFFIC' && !brick.data.settings.destination_type",
        //     message: 'Campaign with objective traffic should have destination type on ad set level',
        //            // },
        {
            condition: "parent?.data?.settings.objective=='OUTCOME_TRAFFIC' && !['WEBSITE','APP','MESSENGER'].includes(brick.data.settings.destination_type)",
            message: 'Campaign with objective traffic should have destination type to WEBSITE, APP or MESSENGER',
            inputIdentifiers: ['meta_adset-destination_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_TRAFFIC' && ['WEBSITE'].includes(brick.data.settings.destination_type) && !['LANDING_PAGE_VIEWS', 'LINK_CLICKS', 'IMPRESSIONS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message:
                'Campaign with objective traffic with destination type WEBSITE should have optimization goal set to LANDING_PAGE_VIEWS, LINK_CLICKS, IMPRESSIONS or REACH',
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_TRAFFIC' && ['APP'].includes(brick.data.settings.destination_type) && !['LINK_CLICKS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message: 'Campaign with objective traffic with destination type APP should have optimization goal set to LINK_CLICKS or REACH',
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_TRAFFIC' && ['MESSENGER'].includes(brick.data.settings.destination_type) && !['LINK_CLICKS', 'IMPRESSIONS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message: 'Campaign with objective traffic with destination type MESSENGER should have optimization goal set to LINK_CLICKS, IMPRESSIONS or REACH',
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        // {
        //     condition: "parent?.data?.settings.objective=='OUTCOME_SALES' && !brick.data.settings.destination_type",
        //     message: 'Campaign with objective sales should have destination type on ad set level',
        //            // },
        {
            condition: "parent?.data?.settings.objective=='OUTCOME_SALES' && !['WEBSITE','APP','MESSENGER'].includes(brick.data.settings.destination_type)",
            message: 'Campaign with objective sales should have destination type to WEBSITE, APP or MESSENGER',
            inputIdentifiers: ['meta_adset-destination_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_SALES' && ['WEBSITE','APP','MESSENGER'].includes(brick.data.settings.destination_type) && (!brick.data.settings.promoted_object.custom_event_type)",
            message: 'Campaign with objective sales with destination type WEBSITE, APP or MESSENGER should have conversions event',
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_SALES' && brick.data.settings.destination_type==='WEBSITE' && brick.data.settings.promoted_object && !['PURCHASE','INITIATED_CHECKOUT','ADD_PAYMENT_INFO','ADD_TO_CART','COMPLETE_REGISTRATION','DONATE','SPENT_CREDITS','START_TRIAL','SUBSCRIBE','CONTENT_VIEW'].includes(brick.data.settings.promoted_object.custom_event_type)",
            message:
                'Campaign with objective sales with destination type WEBSITE can have only Purchase, Initiate Checkout, Add Payment Info, Add to Cart, Complete Registration, Donate, In-App Ad Click, In-App Ad Impression, Spent Credits, Start Trial, Subscribe, View Content as conversion events',
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_SALES' && brick.data.settings.destination_type==='WEBSITE' && !['OFFSITE_CONVERSIONS', 'LINK_CLICKS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message:
                'Campaign with objective sales with destination type WEBSITE can have only App Events, Link Clicks, Daily Unique Reach as optimization goal',
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_SALES' && brick.data.settings.destination_type==='MESSENGER' && brick.data.settings.promoted_object && !['PURCHASE','INITIATED_CHECKOUT','ADD_PAYMENT_INFO','ADD_TO_CART','COMPLETE_REGISTRATION','DONATE','START_TRIAL','SUBSCRIBE','CONTENT_VIEW'].includes(brick.data.settings.promoted_object.custom_event)",
            message:
                'Campaign with objective sales with destination type MESSENGER can have onlyPurchase, InitiateCheckout, AddPaymentInfo, AddToCart, CompleteRegistration, Donate, StartTrial, Subscribe, ViewContent as conversion events',
            inputIdentifiers: ['meta_adset-destination_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_SALES' && brick.data.settings.destination_type==='MESSENGER' && !['CONVERSATIONS', 'OFFSITE_CONVERSIONS', 'LINK_CLICKS', 'IMPRESSIONS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message:
                'Campaign with objective sales with destination type MESSENGER can have only Conversations, Conversions, Link Clicks, Impressions, Reach as optimization goal',
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_SALES' && brick.data.settings.destination_type==='APP' && brick.data.settings.promoted_object && !['PURCHASE','INITIATED_CHECKOUT','ADD_PAYMENT_INFO','ADD_TO_CART','COMPLETE_REGISTRATION','SPENT_CREDITS','START_TRIAL','SUBSCRIBE','CONTENT_VIEW'].includes(brick.data.settings.promoted_object.custom_event)",
            message:
                'Campaign with objective sales with destination type APP can have only Purchase, Initiate Checkout, Add Payment Info, Add to Cart, Complete Registration, Donate, In-App Ad Click, In-App Ad Impression, Spent Credits, Start Trial, Subscribe, View Content as conversion events',
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective=='OUTCOME_SALES' && brick.data.settings.destination_type==='APP' && !['OFFSITE_CONVERSIONS', 'LINK_CLICKS', 'REACH'].includes(brick.data.settings.optimization_goal)",
            message: 'Campaign with objective sales with destination type APP can have only App Events, Link Clicks, Daily Unique Reach as optimization goal',
            inputIdentifiers: ['meta_adset-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.buying_type && parent?.data?.settings.buying_type!='AUCTION' && brick.data.settings.billing_event!=='IMPRESSIONS'",
            message: "Campaign with buying type different than 'Auction' should have billing event 'Impressions'",
            inputIdentifiers: ['meta_adset-billing_event'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "(!parent?.data?.settings.buying_type || parent?.data?.settings.buying_type === 'AUCTION' ) && brick.data.settings.optimization_goal && brick.data.settings.optimization_goal === 'LINK_CLICKS' && brick.data.settings.billing_event && brick.data.settings.billing_event !== 'LINK_CLICKS' && brick.data.settings.billing_event !== 'IMPRESSIONS'",
            message:
                "Campaign with buying type 'Auction' and optimization goal 'Link clicks' should have 'Link clicks' or 'Impressions' as billing events on its sets",
            inputIdentifiers: ['meta_adset-billing_event'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "(!parent?.data?.settings.buying_type || parent?.data?.settings.buying_type === 'AUCTION' ) && brick.data.settings.optimization_goal && brick.data.settings.optimization_goal === 'THRUPLAY' && brick.data.settings.billing_event && brick.data.settings.billing_event !== 'IMPRESSIONS' && brick.data.settings.billing_event !== 'THRUPLAY'",
            message:
                "Campaign with buying type 'Auction' and optimization goal 'Thruplay' should have 'Impressions' or 'Thruplay' as billing events on its sets",
            inputIdentifiers: ['meta_adset-billing_event'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "(parent?.data?.settings.objective === 'OUTCOME_ENGAGEMENT' && ((!parent?.data?.settings.promoted_object && !brick.data.settings.promoted_object) || ((parent?.data?.settings.promoted_object && !(parent?.data?.settings.promoted_object.pixel_id && parent?.data?.settings.promoted_object.pixel_id.includes('custom')) && !(parent?.data?.settings.promoted_object.pixel_id && parent?.data?.settings.promoted_object.custom_event_type) && !(parent?.data?.settings.promoted_object.pixel_id && parent?.data?.settings.promoted_object.custom_event_type && parent?.data?.settings.promoted_object.pixel_rule) && !(parent?.data?.settings.promoted_object.event_id && parent?.data?.settings.promoted_object.custom_event_type) && !(parent?.data?.settings.promoted_object.application_id && parent?.data?.settings.promoted_object.object_store_url && parent?.data?.settings.promoted_object.custom_event_type) && !(parent?.data?.settings.promoted_object.offline_conversion_data_set_id && parent?.data?.settings.promoted_object.custom_event_type) && !parent?.data?.settings.promoted_object.page_id)) || ((brick.data.settings.promoted_object && !(brick.data.settings.promoted_object.pixel_id && brick.data.settings.promoted_object.pixel_id.includes('custom')) && !(brick.data.settings.promoted_object.pixel_id && brick.data.settings.promoted_object.custom_event_type) && !(brick.data.settings.promoted_object.pixel_id && brick.data.settings.promoted_object.custom_event_type && brick.data.settings.promoted_object.pixel_rule) && !(brick.data.settings.promoted_object.event_id && brick.data.settings.promoted_object.custom_event_type) && !(brick.data.settings.promoted_object.application_id && brick.data.settings.promoted_object.object_store_url && brick.data.settings.promoted_object.custom_event_type) && !(brick.data.settings.promoted_object.offline_conversion_data_set_id && brick.data.settings.promoted_object.custom_event_type) && !brick.data.settings.promoted_object.page_id))))",
            message: "Campaign with objective 'Engagement' should have promoted object with certain values on campaign or ad set level",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.objective ==='OUTCOME_APP_PROMOTION' && ((!parent?.data?.settings.promoted_object && !brick.data.settings.promoted_object) || (parent?.data?.settings.promoted_object && !((!brick.data.settings.optimization_goal || brick.data.settings.optimization_goal !== 'OFFSITE_CONVERSIONS') && parent?.data?.settings.promoted_object.application_id && parent?.data?.settings.promoted_object.object_store_url) && !(brick.data.settings.optimization_goal && brick.data.settings.optimization_goal === 'OFFSITE_CONVERSIONS' && parent?.data?.settings.promoted_object.application_id && parent?.data?.settings.promoted_object.object_store_url && parent?.data?.settings.promoted_object.custom_event_type)) || (brick.data.settings.promoted_object && !((!brick.data.settings.optimization_goal || brick.data.settings.optimization_goal !== 'OFFSITE_CONVERSIONS') && brick.data.settings.promoted_object.application_id && brick.data.settings.promoted_object.object_store_url) && !(brick.data.settings.optimization_goal && brick.data.settings.optimization_goal === 'OFFSITE_CONVERSIONS' && brick.data.settings.promoted_object.application_id && brick.data.settings.promoted_object.object_store_url && brick.data.settings.promoted_object.custom_event_type)))",
            message: "Campaign with objective 'App promotion' should have promoted object in campaign level and in set level",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: "parent?.data?.settings.objective === 'OUTCOME_SALES' && !brick?.data?.settings?.promoted_object",
            message: "Campaign with objective 'Sales' should have promoted object in campaign level and in set level",
            inputIdentifiers: ['meta_adset-promoted_obect-event_type'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "parent?.data?.settings.daily_budget && (brick.data.settings.bid_strategy === 'LOWEST_COST_WITHOUT_CAP' && ((brick.data.settings.billing_event === 'IMPRESSIONS' && parent?.data?.settings.daily_budget < 0.5) || (brick.data.settings.billing_event === 'PAGE_LIKES' && parent?.data?.settings.daily_budget < 2.5) || (brick.data.settings.billing_event === 'CLICKS' && parent?.data?.settings.daily_budget < 2.5) || (brick.data.settings.billing_event === 'CLICKS' && parent?.data?.settings.daily_budget < 2.5))) || (brick.data.settings.bid_strategy === 'LOWES_COST_WITH_BID_CAP' && ((brick.data.settings.billing_event === 'IMPRESSIONS' && parent?.data?.settings.daily_budget > brick.data.settings.bid_amount) || (brick.data.settings.billing_event === 'CLICKS' && parent?.data?.settings.daily_budget > brick.data.settings.bid_amount * 5)))",
            message: 'Campaign daily budget is not properly set',
            inputIdentifiers: ['meta_adset-budget'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: "brick.data.settings.bid_strategy !== 'LOWEST_COST_WITHOUT_CAP' && brick?.data?.settings?.optimization_goal === 'AD_RECALL_LIFT'",
            message: 'Your bid strategy should be set to highest volime if you are optimizing for ad recall lift',
            inputIdentifiers: ['meta_adset-bid_strategy'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: "!brick.data.settings.billing_event || brick.data.settings.billing_event===''",
            message: 'Billing event should be set',
            inputIdentifiers: ['meta_adset-billing_event'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                "(parent?.data?.settings.budget_optimization_on==='false' || !parent?.data?.settings.bid_strategy || parent?.data?.settings.bid_strategy==='')  &&  brick.data.settings.bid_strategy===''",
            message: 'Bid strategy should be set should be set either on campaign or on set level',
            inputIdentifiers: ['meta_adset-bid_strategy'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '!brick.data.settings.targeting || !brick.data.settings.targeting.geo_locations || !brick.data.settings.targeting.geo_locations.locations || !brick.data.settings.targeting.geo_locations.locations.length',
            message: 'At least one location should be added',
            inputIdentifiers: ['meta_adset-locations'],
            tabKey: 'settings',
            severity: 'error'
        },

        {
            condition: 'brick.data.settings.budget_mode==="LIFETIME" && !brick.data.settings.end_time',
            message: 'If the budget mode is lifetime the ad set should have end date',
            inputIdentifiers: ['meta_adset-end_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.data.settings.budget || brick.data.settings.budget==="0"',
            message: 'Ad set should have budget',
            inputIdentifiers: ['meta_adset-budget'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '(!brick.data.settings.bid_amount || brick.data.settings.bid_amount==="0") && (brick.data.settings.bid_strategy==="LOWES_COST_WITH_BID_CAP" || (parent?.data?.settings.budget_optimization_on==="true" && parent?.data?.settings.bid_strategy==="LOWES_COST_WITH_BID_CAP"))',
            message: 'Ad set should have budget',
            inputIdentifiers: ['meta_adset-budget'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '!brick.data?.settings?.targeting?.placements?.includes("instagram-stream") && brick.data?.settings?.targeting?.placements?.includes("instagram-explore")',
            message: 'For using Instagram Explore placement you need to select Instagram Feed',
            inputIdentifiers: ['meta_adset-placements'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '!brick.data?.settings?.targeting?.placements?.includes("instagram-stream") && brick.data?.settings?.targeting?.placements?.includes("instagram-explore_home")',
            message: 'For using Instagram Explore Home placement you need to select Instagram Feed',
            inputIdentifiers: ['meta_adset-placements'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '(parent?.data?.settings.objective=="REACH" || brick.data?.settings?.optimization_goal === "REACH") && brick.data?.settings?.frequency_control_specs?.interval_days && (Number(brick.data?.settings?.frequency_control_specs?.interval_days) > 90 || Number(brick.data?.settings?.frequency_control_specs?.interval_days) < 1)',
            message: 'The interval days should be between 1 and 90',
            inputIdentifiers: ['meta_adset-frequency_control_specs-frequency_interval'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition:
                '(parent?.data?.settings.objective=="REACH" || brick.data?.settings?.optimization_goal === "REACH") && brick.data?.settings?.frequency_control_specs?.max_frequency && (Number(brick.data?.settings?.frequency_control_specs?.max_frequency) > 90 || Number(brick.data?.settings?.frequency_control_specs?.max_frequency) < 1)',
            message: 'Max frequency should be between 1 and 90',
            inputIdentifiers: ['meta_adset-frequency_control_specs-max_frequency'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
